import styled from "styled-components";

const Header = () => {
  return (
    <>
      <Wrapper>
        <Title>CSStudios</Title>
        <Button className="btn">GET IN TOUCH</Button>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  @media (max-width: 560px) {
    justify-content: center;
  }
`;

const Title = styled.h1`
  cursor: pointer;
  color: white;
  font-size: 40px;
  font-family: "Archivo Black", sans-serif;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
  }
`;

const Button = styled.button`
  color: white;
  font-weight: bold;
  border: 3px solid white;
  outline: none;
  background-color: transparent;
  padding: 12px 22px;
  border-radius: 30px;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: black;
    background-color: white;
  }

  @media (max-width: 560px) {
    &.btn {
      display: none;
    }
  }
`;

export default Header;
