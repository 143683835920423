import styled from "styled-components";

import bg from "../../assets/BG.jpg";
import Footer from "../Footer";
import Header from "../Header";

import { send } from "@emailjs/browser";
import { useState } from "react";

import "./Home.css";

const Home = () => {
  const [toSend, setToSend] = useState({
    user_email: "",
  });
  const [msg, setMsg] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (toSend.user_email !== "" && toSend.user_email.includes("@")) {
      send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        toSend,
        process.env.REACT_APP_PUBLIC_KEY
      )
        .then((res) => {
          if (res.status === 200) {
            setToSend({ user_email: "" });
            setMsg("Stay tuned, we are creating madness!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMsg("Please enter your email before submitting.");
    }
  };

  const handleChange = (e) => {
    setToSend({ [e.target.name]: e.target.value });
  };

  return (
    <>
      <Section>
        <Header />
        <Wrapper>
          <Title>Coming Soon.</Title>
          <InputWrap>
            <form onSubmit={onSubmit}>
              <Input
                type="email"
                name="user_email"
                value={toSend.user_email}
                onChange={handleChange}
                placeholder="Email"
              />
              <Button>Sign Up</Button>
            </form>
          </InputWrap>
          <p>{msg}</p>
        </Wrapper>
        <Footer />
      </Section>
    </>
  );
};

const Section = styled.section`
  width: 100vw;
  height: 100vh;
  background-image: url(${bg});
  background-size: cover;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: white;
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
    margin: 20px;
    letter-spacing: 3px;
  }
`;

const Title = styled.h1`
  color: white;
  font-family: "Times New Roman", Times, serif;
  font-size: 70px;
  padding: 20px;
  letter-spacing: 3px;

  @media (max-width: 485px) {
    font-size: 50px;
  }

  @media (max-width: 360px) {
    font-size: 40px;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  color: white;
  height: 45px;
  width: 250px;
  margin: 0 10px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid white;
  outline: none;
  border-radius: 8px;
  background-color: transparent;

  &::placeholder {
    font-size: 16px;
  }

  @media (max-width: 485px) {
    height: 35px;
    width: 175px;
    font-size: 16px;
  }
`;

const Button = styled.button`
  font-size: 17px;
  color: white;
  font-weight: bold;
  border: 3px solid white;
  outline: none;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: black;
    background-color: white;
  }

  @media (max-width: 485px) {
    font-size: 13px;
  }
`;

export default Home;
